import React from "react";
import styled from "styled-components";

const ServiceTextStyle = styled.section`
  padding: 6rem 0;
  text-align: center;
  max-width: 60%;
  margin: 0 auto;
  p:first-of-type {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 900;
  }
  p:last-of-type {
    color: ${(props) => props.theme.darkGray};
    line-height: 1.8;
  }
  @media (max-width: 768px) {
    padding: 3rem 0;
    max-width: 100%;
  }
`;

const ServicesText = () => {
  return (
    <div className="container">
      <ServiceTextStyle>
        <p>
          Nyílászárók gyártása, forgalmazása és kivitelezése. Tökéletes
          hőszigetelés modern kivitelezés.
        </p>
        <p>
          A gyártást követően a kivitelési munkákat is vállaljuk, a
          munkáinksorán mindig betartjuk az ütemezési tervet, ezért elégedettek
          velünk a korábbi partnereink. Nálunk mindig a megrendelő az első,
          rugalmasan alkalmazkodunk az igényeikhez és tartjuk a megbeszélt
          határidőket
        </p>
      </ServiceTextStyle>
    </div>
  );
};

export default ServicesText;
