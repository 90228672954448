import React from "react";
import styled from "styled-components";
import { servicesCards } from "../../statics/servicesCards.static";
import ServicesCard from "./ServicesCard.component";

const ServicesCardsStyle = styled.section`
  background: url("/service-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0;
`;

const ServicesCardsWrapperStyle = styled.section`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 3rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServicesCards = () => {
  const triColor = ["purple", "darkOrange", "orange"];
  return (
    <ServicesCardsStyle>
      <div className="container">
        <ServicesCardsWrapperStyle>
          {servicesCards.map((sc, i) => (
            <ServicesCard {...sc} key={`sc${i}`} triColor={triColor[i]} />
          ))}
        </ServicesCardsWrapperStyle>
      </div>
    </ServicesCardsStyle>
  );
};

export default ServicesCards;
