import React from "react";
import BC from "../components/General/BC.component";
import Layout from "../components/General/Layout.component";
import { servicesPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";
import IndexPageAboutCarousel from "../components/Index/IndexPageAboutCarousel.component";
import ContactForm from "../components/Contact/ContactForm.component";
import ServicesText from "../components/Services/ServicesText.component";
import ServicesCards from "../components/Services/ServicesCards.component";

const szolgaltatasaink = () => {
  return (
    <Layout>
      {genPageInfo(servicesPage)}
      <BC
        title="Szolgáltatásaink"
        sub={[{ text: "Kezdőlap", href: "/" }, { text: "Szolgáltatásaink" }]}
      />
      <ServicesText />
      <ServicesCards />
      <IndexPageAboutCarousel />
      <ContactForm />
      <div style={{ height: "10rem", background: "white" }}></div>
    </Layout>
  );
};

export default szolgaltatasaink;
