export const servicesCards = [
  {
    lead: "Gyártás",
    sub: "Nyílászárók gyártása",
    content:
      "Kiváló minőségű alapanyagok felhasználásával dolgozunk. A gyártási folyamat során követjük a magas minőségi gyártásra vonatkozó irányelveket.",
    list: ["Családiházak", "Társasházak", "Irodák", "Üzletek"],
    small:
      "FONTOS: A gyártási folyamat minden esetben a vevőink igénye alapján történik és nyitottak vagyunk az egyedi, különleges elképzelések megvalósítására is.",
  },
  {
    lead: "Forgalmazás",
    sub: "Nyílászárók forgalmazása",
    content:
      "Raktárkészletről kínálunk standard méretű ablakokat vásárlóink számára, hogy a lehető leghamarabb beépítésre kerülhessen a kiválasztott nyílászáró.",
    list: [
      "Óriási választék",
      "Hatalmas raktárkészlet",
      "24 órás szállítás",
      "Kedves ügyfélszolgálat",
    ],
    small:
      "FONTOS: Lakossági vevők és kivitelezők számára egyedi tervezést, gyártást és beépítést vállalunk, pontosan betartott határidőkkel.",
  },
  {
    lead: "Partnerség",
    sub: "Értékesítés partnerek számára",
    content:
      "Az építőiparban jól ismert vállalkozásként olyan kölcsönösen előnyös együttműködési lehetőséget ajánlunk, amely hosszútávon is kiszámítható termelést biztosít vállalkozásának.",
    list: [
      "Több éves tapasztalat",
      "Magas minpség",
      "Hosszútávú garancia",
      "Kedves ügyfélszolgálat",
    ],
    small:
      "FONTOS: Látogasson el hozzánk, ismerje meg a gyártási folyamatot és keressük meg azokat a területeket ahol partnereként segítünk üzleti céljai elérésében!",
  },
];
