import React from "react";
import Slider from "react-slick";
import LimitlessSolutions from "../../images/hatartalan-megoldasok.svg";
import QualityGuarantee from "../../images/minosegi-garancia.svg";
import GreatPrices from "../../images/kedvezo-arak.svg";
import styled from "styled-components";

const IndexPageAboutCarouselItem = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 0;
  gap: 2rem;
  & p:first-of-type {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  & p:last-of-type {
    color: ${({ theme }) => theme.darkGray};
    line-height: 1.8;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const IndexPageAboutCarouselIcon = styled.div`
  background: ${({ theme, iconColor }) => theme[iconColor] || iconColor};
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: grid;
  place-items: center;
  min-width: 6rem;
  & svg {
    width: 3rem;
    height: 3rem;
  }
`;

const IndexPageAboutCarousel = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className="container">
      <Slider {...sliderSettings}>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="orange">
            <LimitlessSolutions />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              Határtalan
              <br />
              megoldások
            </p>
            <p>Nincs az a méret amit ne tudnánk legyártani Önnek.</p>
          </div>
        </IndexPageAboutCarouselItem>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="#ff8135">
            <QualityGuarantee />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              Minőségi
              <br />
              garancia
            </p>
            <p>
              Az általáunk beszerelt nyílászárókra <strong>10 év</strong>{" "}
              garanciát vállalunk.
            </p>
          </div>
        </IndexPageAboutCarouselItem>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="#b3bcea">
            <GreatPrices />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              Kedvező
              <br />
              árak
            </p>
            <p>Igyekszünk a legkedvezőbb árat biztosítani megrendelőinknek.</p>
          </div>
        </IndexPageAboutCarouselItem>
      </Slider>
    </div>
  );
};

export default IndexPageAboutCarousel;
