import React from "react";
import styled from "styled-components";

const ServicesCardStyle = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4rem 2rem;
  height: 100%;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.darkGray};
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10rem 6rem 0 0;
    border-color: ${(props) => props.theme[props.triColor]} transparent
      transparent transparent;
  }
  .lead,
  .sub {
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }
  .lead {
    font-size: 3rem;
    color: black;
  }
  .sub {
    margin-bottom: 2rem;
  }
  .content {
    text-align: center;
    padding-bottom: 4rem;
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
    line-height: 1.8;
    margin-bottom: 4rem;
  }
  .list {
    margin-bottom: 4rem;
    div {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      &:before {
        content: "";
        position: relative;
        width: 2rem;
        background: ${(props) => props.theme.darkBlue};
        height: 2px;
        display: inline-flex;
        margin-right: 1.5rem;
      }
    }
  }
  .smalltext {
    font-size: 1.2rem;
  }
`;

const ServicesCard = (props) => {
  return (
    <ServicesCardStyle triColor={props.triColor}>
      <div className="lead">{props.lead}</div>
      <div className="sub">{props.sub}</div>
      <div className="content">{props.content}</div>
      <div className="list">
        {props.list.map((l, i) => (
          <div key={`li${i}`}>{l}</div>
        ))}
      </div>
      <div className="smalltext">{props.small}</div>
    </ServicesCardStyle>
  );
};

export default ServicesCard;
